(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("crypto"), require("fs"), require("path"));
	else if(typeof define === 'function' && define.amd)
		define(["crypto", "fs", "path"], factory);
	else if(typeof exports === 'object')
		exports["filestack"] = factory(require("crypto"), require("fs"), require("path"));
	else
		root["filestack"] = factory(root["crypto"], root["fs"], root["path"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__59__) {
return 